
import { createRouter, createWebHistory } from "vue-router";



const routes = [
  { path: '/', name:'Home',  component:()=>import('./MyHome.vue') },
  { path: '/about', name:'About', component:()=>import('./MyAbout.vue') },
  { path: '/article/:articleId', name:'Article', component:()=>import('./ArticleDetail.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

