

<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view></router-view>
</template>
<style>
</style> 

<script>
// import HelloWorld from './components/HelloWorld.vue'

// <template>
//   <router-link to="/">Go to Home</router-link>
//   <router-link to="/about">Go to About</router-link>
//   <router-view />
//   <img alt="Vue logo" src="./assets/logo.png">
//   <HelloWorld msg="Welcome to Dongliang"/>
// </template>

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
