import { createApp } from 'vue'
import App from "./App";
import router from './router'


// Vue.config.productionTip = false
// Vue.prototype.$axios = axios

// createApp(App).mount('#app')

const app = createApp(App)
app.use(router).mount('#app')

// export default router
